import { Box, Modal } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import { constants } from "../../data/data";
import { NextBtn, PreviousBtn } from "./helper/Slider";

export const Events = (props) => {
  console.log(window.innerWidth);
  const width = window.innerWidth;
  return (
    <>
      {width > 900 && (
        <div
          className="community-container"
          id="irlevents"
          sx={{
            display: {
              md: "none",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <div className="container">
            <div className="row blogcard-container">
              <div className="col-xs-12 col-md-4 community-heading-wrapper">
                <p className="blogcard-heading">
                  IRL Events
                  <img
                    src="img/dash.svg"
                    className="blogcard-subheading"
                    alt="dash"
                  />
                </p>
              </div>
            </div>
            <div className="row community-logo-container">
              <div className="col-xs-12 col-md-4 community-partner-wrapper">
                <Partner community={constants.Events} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const Partner = (props) => {
  const [open, setOpen] = React.useState(false);
  const [imgUrl, setImgUrl] = React.useState("");
  const handleOpen = (id) => {
    console.log("inner Width", window.innerWidth, id);
    if (window.innerWidth > 425) {
      setImgUrl(id);
      setOpen(true);
    } else {
      setImgUrl(0);
      setOpen(false);
    }
  };
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: 550, sm: "100%" },

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 1,
  };

  const settings = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 4,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {props.community.map((img, index) => (
          <div
            key={img.path}
            style={{ display: "flex" }}
            onClick={() => handleOpen(index + 1)}
          >
            <img
              src={img.path}
              height={"190px"}
              width={"100%"}
              style={{ padding: "8px", cursor: "pointer" }}
            />
          </div>
        ))}
      </Slider>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              hight: "100%",
            }}
          >
            <img
              src={`img/events/image${imgUrl}.png`}
              width={"100%"}
              height="100%"
            ></img>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
