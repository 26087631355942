import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      <div
        className={className}
        onClick={onClick}
        style={{ left: "0px", zIndex: 9999999 }}
      >
        <ArrowBackIosNewIcon
          sx={{
            color: "rgb(246, 246, 246,50%)",
            fontSize: "40px",
            borderRadius: "2px",
            background: "none",
            height: "100px",
            transition: "1s",
            border: "1px solid ",
            "&:hover": {
              border: "1px solid rgb(246, 246, 246,50%)",
              background: "rgb(246, 246, 246,50%)",
            },
            fill: "#fff",
          }}
        />
      </div>
    </>
  );
};
export default PreviousBtn;
