import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      <div className={className} onClick={onClick} style={{ right: "20px" }}>
        <ArrowForwardIosIcon
          sx={{
            color: "rgb(246, 246, 246,50%)",
            fontSize: "40px",
            borderRadius: "2px",
            background: "none",
            height: "80px",
            transition: "1s",
            border: "1px solid ",
            "&:hover": {
              border: "1px solid rgb(246, 246, 246,50%)",
              background: "rgb(246, 246, 246,50%)",
            },
            fill: "#fff",
          }}
        />
      </div>
    </>
  );
};
export default NextBtn;
