// import './NftBuy.css';
const BenifitData = [
    { title: "Voting rights." },
    { title: "Governance of the COI Society." },
    { title: "Creating proposals." },
    { title: "Project (PODS) funding." },
    { title: "Blue-chip NFTs to be purchased would be community voted through polls." },
    { title: "DOA ownership of the COI vault that holds valuable NFT’s. " },
    { title: "The COI DAO vault is owned by the holders of the COI token / COI DAO NFT" },
    { title: "Special access to discord COI DAO channel" },
    { title: "Exclusive access to some weekly sessions by industry experts ( The savvy collector / Art therapy / DLT / Metaverse / )" }
]
const BenifitData2 = [
    { title: "Limited edition COI POAP" },
    { title: "Limited edition COI Digital wearables." },
    { title: "Access to early access pass / pre-mint / limited edition NFT's from the community." },
    { title: "Special access to get whitelisted on projects." },
    { title: "Access to IRL events (MetaconXx) ." },
    { title: "Access to global IRL events." },
    { title: "Access to IRL events." },
    { title: "Marketing support / social media visibility / PR Community would be heavily exposed to NFT space through COI members who are experts in the field." }
]

const Benifit = () => {
    return (

        <div className="container-fluild py-5" style={{ background: "#060c27" }} id="benifits">
            <div className="container">
                <div className="row">
                    <div className='row art-container '>
                        <div className='col-sm-12 col-md-5 artgallery-heading-wrapper'>
                            <p className='benifits-heading'>
                                COI DAO NFT holder Artist & Collector Benifits
                                <img
                                    src='img/dash.svg'
                                    className='artgallery-subheading'
                                    alt='dash'
                                />
                            </p>
                        </div>
                    </div>
                    <div className="row py-5 d-flex justify-content-center " >
                        <div className='col-sm-12 col-md-6'>
                            {BenifitData.map((data, index) =>
                                <div className="d-flex py-1" key={index}>
                                    <p className="d-flex  textbenifits align-items-center justify-content-center"><i class="fas fa-hand-point-right" ></i>&nbsp;&nbsp;</p>
                                    <p className="textbenifits " >
                                        {data.title}</p>
                                </div>
                            )}
                        </div>
                        <div className='col-sm-12 col-md-6 '>
                            {BenifitData2.map((data, index) =>
                                <div className="d-flex py-1" key={index}>
                                    <p className="d-flex  textbenifits align-items-center justify-content-center"><i class="fas fa-hand-point-right" ></i>&nbsp;&nbsp;</p>
                                    <p className="textbenifits " >
                                        {data.title}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        // <div className="welcome-container" id="home" >
        //     <ModalComponent
        //         show={modalShow}
        //         onHide={() => setModalShow(false)}
        //         heading={"About COI"}
        //         content={constants.KnowMore.aboutCoi}
        //     />
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-xs-12 col-md-5">
        //                 <div className="card" id="orangeButton" style={{ borderRadius: '0px ' }}>
        //                     <video autoPlay loop muted>
        //                         <source src={nftvideo} type="video/mp4" className="border" />
        //                     </video>
        //                 </div>
        //                 <div className="row ">
        //                     <div className="col">
        //                         <p className="welcome-subheading">Total Mint</p>
        //                     </div>
        //                     <div className="col">
        //                         <p className="text-end welcome-subheading">{tokenSupply}/{totalMaxSupply}</p>
        //                     </div>
        //                 </div>
        //                 <div className="row justify-content-center">
        //                     <div className="col-8 ">
        //                         {!walletConnect && (
        //                             <button
        //                                 className="btn addOrangeNeon w-100"
        //                                 onClick={connectWalletHandler}
        //                                 style={{
        //                                     backgroundColor: '#1212',
        //                                     borderColor: '#F4C773',
        //                                     color: '#F4C773',
        //                                     height: '80px',
        //                                     fontSize: '20px',
        //                                     borderRadius: '2rem'
        //                                 }}
        //                             >
        //                                 Connect Wallet
        //                             </button>
        //                         )}
        //                         {allowMint && walletConnect && (
        //                             <button className="btn addOrangeNeon w-100 " style={{
        //                                 backgroundColor: '#1212',
        //                                 borderColor: '#F4C773',
        //                                 color: '#F4C773',
        //                                 height: '80px',
        //                                 fontSize: '20px',
        //                                 borderRadius: '2rem'
        //                             }} onClick={mintHandler}>
        //                                 Mint NFT
        //                             </button>
        //                         )}
        //                         {!allowMint && walletConnect && (
        //                             <button className="btn addOrangeNeon w-100 " style={{
        //                                 backgroundColor: '#1212',
        //                                 borderColor: '#F4C773',
        //                                 color: '#F4C773',
        //                                 height: '80px',
        //                                 fontSize: '20px',
        //                                 borderRadius: '2rem'
        //                             }}>
        //                                 You Can Not Mint NFT
        //                             </button>
        //                         )}
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="col-xs-12 col-md-5 offset-md-2  ">
        //                 <div className=" align-items-center d-flex  justify-content-center h-100">
        //                     <div>
        //                         <p className="welcome-heading text-center ">
        //                             COLOURS OF INDIA (COI) DAO
        //                         </p>
        //                         <p className='welcome-subheading'>
        //                             We are on a mission to bring Indian NFT art to the world! As the
        //                             first DAO of its kind in the subcontinent, we aim to develop a
        //                             vibrant culture of diversity, openness and freedom of expression.
        //                             Join the movement.
        //                         </p>
        //                         <p>
        //                             <a
        //                                 href={props.data ? props.data.discord : '/'}
        //                                 className='btn btn-custom btn-lg know-more-anchor'
        //                                 style={{
        //                                     backgroundColor: '#4daeb4',
        //                                     borderColor: '#4daeb4',
        //                                 }}
        //                                 target='blank'
        //                                 onClick={(event) => {
        //                                     event.preventDefault();
        //                                     setModalShow(true);
        //                                 }}
        //                             >
        //                                 Know More
        //                             </a>
        //                         </p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}
export default Benifit