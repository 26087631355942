import SmoothScroll from "smooth-scroll";
import NftBuy from "./components/redesign/NftBuy";
import Home from "./components/redesign/Home";
import RoadMap from "./components/redesign/RoadMap";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/nft" element={<NftBuy />} /> */}
        {/* <Route path="/roadmap" element={<RoadMap />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
