import React from 'react'
import { Header } from './header'
import { Welcome } from './welcome'
import { Artgallery } from './artgallery'
import { Upcoming } from './upcoming'
import { Faq } from './faq'
import { Communitypartner } from './communitypartner'
import { Events } from './Events'
import { Newsletter } from './newsletter'
import Benifit from './Benifit';
import RoadMap from './RoadMap'


export default function Home() {
  return (
    <div className='main-container'>
      <Header />
      <Welcome />
      <Artgallery />
      <Upcoming />

      {/* <RoadMap></RoadMap> */}
      <Faq />
      <Benifit />
      <Communitypartner />
      <Events/>
      <Newsletter />
    </div>
  )
}
