import { alpha, createTheme } from "@mui/material/styles";

export const colorPallete = {
  lightBlue: "#76A3C5",
  pimarydark: "#012326",
  secoundryDark: "#031C3D",
  lightOrange: "#F28B0C",
  secoundyLight: "#003C50",
  background: "#eaeded",
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#012326",
      light: "#f6f6f6",
      dark: "#232f3e",
    },
    secondary: {
      main: "#1D4354",
      dark: "#031C3D",
    },
  },
  shape: {
    borderRadius: 5,
  },
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 900,
      lg: 1548,
      xl: 1920,
    },
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.1,
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: alpha("#FFFFFF", 1),
          padding: "0.0rem",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          WebkitFontSmoothing: "auto",
        },
        body: {
          background: "#fffff",
          backgroundRepeat: "no-repeat",
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: alpha("#FFF", 0.02),
          backgroundImage: "none",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "24px",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: "transparent",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          color: "#000",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
        badge: {
          background: "#E38B29",
          color: "#000",
          height: 20,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: "0.125rem",
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: "normal",
          marginBottom: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        action: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
        },
        message: {
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "flex-start",
        },
        icon: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        filled: {
          minWidth: "500px",
          backdropFilter: "blur(16px)",
        },
        filledInfo: {
          backgroundColor: alpha("#43A5FF", 0.1),
          color: "#fff",
        },
        filledSuccess: {
          backgroundColor: alpha("#F2BC68", 0.1),
          color: "#fff",
        },
        filledError: {
          backgroundColor: alpha("#F55555", 0.1),
          color: "#fff",
        },
        filledWarning: {
          backgroundColor: alpha("#FF8A1E", 0.1),
          color: "#fff",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: "4rem",
          height: "2rem",
          padding: 0,
          marginLeft: "0.25rem",
        },
        track: {
          borderRadius: "1.5rem",
          border: `2px solid ${alpha("#FFFFFF", 0.24)}`,
          background: "transparent",
        },
        switchBase: {
          color: "white",
          "&.Mui-checked": {
            color: "white",
            right: "0.625rem",
            left: "inherit",
            "& + .MuiSwitch-track": {
              background: "#F2BC68",
              opacity: 1,
            },
          },
        },
        thumb: {
          width: "1.5rem",
          height: "1.5rem",
          position: "relative",
          top: "-0.313rem",
          left: "-0.313rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          backgroundColor: "#fff",
          textTransform: "capitalize",
          border: "1px solid #ccc",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
          borderRadius: "8px",
          lineHeight: "20px",
          color: "#000",
          "&:hover": {
            backgroundColor: "#000",
            color: "#F6f6f6",
          },
        },
        contained: {
          borderRadius: "8px",
          boxShadow: "0 2px 5px 0 rgb(213 217 217 / 50%)",
          background: "#FFD814",
          border: "1px solid #FCD200",
          color: "#000",
          margin: "10px 0px",
          padding: "10px 0",
          "&:hover": {
            background: "#ffea80",
            color: "#000",
            boxShadow: "none",
          },
          "&:disabled": {
            backgroundColor: "#ffd8143b",
            color: "#000",
          },
        },
        text: {
          height: "33px",
          border: "1px solid",
          borderColor: "#c89411 #b0820f #99710d",
          borderRadius: "3px",
          background: "-webkit-linear-gradient(top,#f8e3ad,#EEBA37)",
          boxShadow: "inset 0 1px 0 0 #fcf3dc",
          fontSize: "13px",
          color: "#111",
          fontFamily: "inherit",
          textDecoration: "none",
          lineHeight: "30px",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          p: 0,
          m: 0,
          "&:hover": {
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
            border: "1px solid #ccc",
            backgroundColor: "#F4F4F4",
            borderRadius: "6px",
            "& .MuiListItemIcon-root": {
              color: "#ff9900",
            },
            "& .MuiListItemText-primary": {
              color: "#ff9900",
            },
          },
        },
      },
    },
  },
});

export default theme;
