import { Burgermenu } from "./burgermenu";
import React, { useState } from "react";

export const Header = (props) => {
  const [openMenu, setOpenMenu] = useState(false);

  const burgerIconClick = () => {
    if (openMenu) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  };

  console.log("window.innerWidth", window.innerWidth);

  return (
    <div className="header-container">
      <div className="container">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col-xs-2 logo-wrapper">
            <a className="logo" href="#page-top">
              <img src="img/h_logo.svg" alt="colours of india logo" />
            </a>
          </div>
          {/* <div className='col-xs-6 navigation-wrapper'>
            <div className='header-search-bar-wrapper'>
             
            </div>
          </div> */}

          <div
            className="col-xs-10 submit-work-wrapper "
            style={{ display: "flex" }}
          >
            {window.innerWidth > 768 ? (
              <>
                <a
                  href={props.data ? props.data.discord : "#artgallery"}
                  className="header-submit-work mx-4"
                  style={{
                    color: "#000",
                  }}
                  target="blank"
                >
                  Art Gallery
                </a>
                <a
                  href={"https://coidao.upstreamapp.com/"}
                  className="header-submit-work mx-4"
                  style={{
                    color: "#000",
                  }}
                  target="blank"
                >
                  DAO
                </a>
                <a
                  href={props.data ? props.data.discord : "#irlevents"}
                  className="header-submit-work mx-4"
                  style={{
                    color: "#000",
                  }}
                  target="blank"
                >
                  IRL Events
                </a>
                <a
                  href={props.data ? props.data.discord : "#benifits"}
                  className="header-submit-work mx-4"
                  style={{
                    color: "#000",
                  }}
                  target="blank"
                >
                  Benifits
                </a>
                <a
                  href={props.data ? props.data.discord : "#partners"}
                  className="header-submit-work mx-4"
                  style={{
                    color: "#000",
                  }}
                  target="blank"
                >
                  Partners
                </a>
                <a
                  href={
                    props.data
                      ? props.data.discord
                      : "https://mint.coloursofindianft.com/"
                  }
                  className="btn btn-custom btn-lg header-submit-work mx-4"
                  style={{
                    backgroundColor: "#4daeb4",
                    borderColor: "#4daeb4",
                  }}
                  target="blank"
                >
                  Mint COI NFT
                </a>
              </>
            ) : (
              <Burgermenu
                burgerIconClick={burgerIconClick}
                iconState={openMenu}
              />
            )}
          </div>
        </div>
        <div className="row" style={{ alignItems: "center" }}>
          <div
            className={
              openMenu
                ? "d-block col-xs-12 col-lg-8 navigation-wrapper-menu"
                : "d-none"
            }
          >
            <div
              className={
                "d-block d-lg-flex col-xs-12 col-lg header-redirections"
              }
            >
              <a className="col-xs-12 btn  btn-lg col-lg" href="#artgallery">
                <span>Art Gallery</span>
              </a>
              <a
                className="col-xs-12 btn  btn-lg col-lg"
                href="https://coidao.upstreamapp.com/"
              >
                <span>DAO</span>
              </a>
              <a className="col-xs-12 btn  btn-lg col-lg" href="#irlevents">
                <span>IRL Events</span>
              </a>

              <a className="col-xs-12 btn  btn-lg col-lg" href="#benifits">
                <span>Benifits</span>
              </a>
              <a className="col-xs-12 btn  btn-lg col-lg" href="#partners">
                <span>Partners</span>
              </a>
              <a
                href={
                  props.data
                    ? props.data.discord
                    : "https://mint.coloursofindianft.com/"
                }
                className="btn  btn-lg col-xs-12 col-lg header-submit-work "
                style={{
                  backgroundColor: "#4daeb4",
                  borderColor: "#4daeb4",
                }}
                target="blank"
              >
                Mint COI NFT
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
